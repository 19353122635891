/** -----------------------------------------------------------
 *
 * Combination of all files that do not generate CSS.
 * Used for styles files in /pages/
 * 
 */
/** --------------------------------------------
 * 
 * Enable/disable imports
 * 
 -------------------------------------------- */
/** --------------------------------------------
 * 
 * Grid
 * 
 -------------------------------------------- */
/**
 * Generic
 */
/**
 * Breakpoints
 */
/** --------------------------------------------
 * 
 * 
 * 
 -------------------------------------------- */
/** --------------------------------------------
 * 
 * 
 * 
 -------------------------------------------- */
/** --------------------------------------------
* 
* 
* 
-------------------------------------------- */
/* 
	Example: 
		$params: (
			'pseudo-element': before,
			'size': 2rem,
			'border-width': .3rem
		);
		@include loading($params...);
*/
/** --------------------------------------------
*
*  Remove top space from headings
*  https://medium.com/codyhouse/line-height-crop-a-simple-css-formula-to-remove-top-space-from-your-text-9c3de06d7c6f
* 
-------------------------------------------- */
/**
 * ps_imageslider
 */
/* line 9, scss/pages/homepage.scss */
.homeslider {
  --p: 1rem;
  --mt--small: 10rem;
  --mb--small: 2.5rem;
  --mt--large: 17.8125rem;
  --mb--large: 8.125rem;
  --mt: var(--mt--small);
  --mb: var(--mb--small);
  position: relative;
}

@media screen and (min-width: 48rem) {
  /* line 9, scss/pages/homepage.scss */
  .homeslider {
    --p: 2rem;
    --mt: calc(var(--mt--small) + (var(--mt--large) - var(--mt--small)) / 2);
    --mb: calc(var(--mb--small) + (var(--mb--large) - var(--mb--small)) / 2);
  }
}

@media screen and (min-width: 75rem) {
  /* line 9, scss/pages/homepage.scss */
  .homeslider {
    --mt: var(--mt--large);
    --mb: var(--mb--large);
  }
}

/* line 30, scss/pages/homepage.scss */
.homeslider--universes {
  --p: 0.5rem;
  --mt--small: 5.3125rem;
  --mb--small: var(--spacing);
  --mt--large: 10.3125rem;
  --mb--large: 2.5rem;
}

@media screen and (min-width: 48rem) {
  /* line 30, scss/pages/homepage.scss */
  .homeslider--universes {
    --p: 1rem;
    --mb: var(--spacing);
  }
}

@media screen and (min-width: 75rem) {
  /* line 30, scss/pages/homepage.scss */
  .homeslider--universes {
    --mb: var(--spacing);
  }
}

/* line 47, scss/pages/homepage.scss */
.homeslider__container {
  position: relative;
  z-index: 2;
  max-width: 34.375rem;
  padding: var(--p) var(--p) calc(var(--p) / 2);
  margin-top: var(--mt);
  margin-bottom: var(--mb);
}

@media screen and (min-width: 375px) {
  /* line 47, scss/pages/homepage.scss */
  .homeslider__container {
    width: 85%;
  }
}

/* line 59, scss/pages/homepage.scss */
.homeslider__container--universes {
  min-width: auto;
  width: calc(100% - var(--spacing-double));
  padding: var(--p);
  margin-left: var(--spacing);
  margin-right: var(--spacing);
  pointer-events: none;
}

@media screen and (min-width: 375px) {
  /* line 59, scss/pages/homepage.scss */
  .homeslider__container--universes {
    max-width: 70%;
  }
}

@media screen and (min-width: 75rem) {
  /* line 59, scss/pages/homepage.scss */
  .homeslider__container--universes {
    width: 50%;
    max-width: none;
  }
}

@media screen and (min-width: 75rem) {
  /* line 77, scss/pages/homepage.scss */
  .homeslider__container--first {
    width: calc(50% - var(--spacing));
  }
}

/* line 84, scss/pages/homepage.scss */
.homeslider__title {
  margin-bottom: 0.25em;
}

/* line 87, scss/pages/homepage.scss */
.homeslider__title:not(:first-child) {
  margin-top: 0.5em;
}

/* line 92, scss/pages/homepage.scss */
.homeslider__link {
  margin-top: calc(var(--p) / 1.5);
  margin-right: calc(var(--p) * -1);
}

/* line 97, scss/pages/homepage.scss */
.homeslider__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* line 106, scss/pages/homepage.scss */
.homeslider__nav {
  position: absolute;
  bottom: calc(var(--mb) / 2);
  left: 50%;
  margin-top: 0;
  transform: translate(-50%, 50%);
}

/**
 * ineat_homepage : Slider universes
 */
/* line 121, scss/pages/homepage.scss */
.universes-slider .tns-inner {
  margin-left: 0 !important;
}

/* line 125, scss/pages/homepage.scss */
.universes-slider .tns-nav {
  margin-top: 0;
  padding-right: var(--spacing);
}

@media screen and (min-width: 75rem) {
  /* line 130, scss/pages/homepage.scss */
  .universes-slider .p-slider__content {
    flex-wrap: wrap;
    width: auto !important;
    margin-left: calc(var(--spacing-half) * -1);
    margin-right: calc(var(--spacing-half) * -1);
  }
}

/* line 139, scss/pages/homepage.scss */
.universes-slider__link {
  position: absolute 0;
  z-index: 1;
  background-color: #fff;
  opacity: 0;
  transition: opacity 0.2s;
}

/* line 146, scss/pages/homepage.scss */
.universes-slider__link:hover, .universes-slider__link.focus-visible {
  opacity: 0.25;
}

/**
 * ineat_homepage : Block Help
 */
/* line 157, scss/pages/homepage.scss */
.help {
  padding-top: var(--spacing-triple);
  padding-bottom: var(--spacing-triple);
  overflow: hidden;
  background-color: var(--color-primary);
}

@media screen and (min-width: 75rem) {
  /* line 157, scss/pages/homepage.scss */
  .help {
    padding-bottom: 0;
    background-color: transparent;
  }
}

@media screen and (min-width: 75rem) {
  /* line 168, scss/pages/homepage.scss */
  .help__col-img {
    z-index: 1;
  }
}

@media screen and (min-width: 75rem) {
  /* line 175, scss/pages/homepage.scss */
  .help__col-text::before {
    content: '';
    position: absolute;
    left: -100vw;
    top: calc(var(--spacing-triple) * -1);
    bottom: calc(var(--spacing-triple) * -1);
    width: 200vw;
    background-color: var(--color-primary);
  }
}

/* line 188, scss/pages/homepage.scss */
.help__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (min-width: 20rem) {
  /* line 188, scss/pages/homepage.scss */
  .help__img {
    height: auto;
  }
}

@media screen and (min-width: 75rem) {
  /* line 188, scss/pages/homepage.scss */
  .help__img {
    height: 31.25rem;
  }
}

/**
 * ineat_homepage : Block Savoir-faire
 */
/* line 208, scss/pages/homepage.scss */
.knowledge__square {
  position: relative;
  overflow: hidden;
}

/* line 213, scss/pages/homepage.scss */
.not-touch .knowledge__square img {
  filter: grayscale(1);
  transition: filter 0.2s;
}

/* line 220, scss/pages/homepage.scss */
.not-touch .knowledge__square:focus-within img, .not-touch .knowledge__square:hover img {
  filter: none;
}

/* line 227, scss/pages/homepage.scss */
.knowledge__link {
  position: absolute;
  right: 0;
  bottom: var(--spacing);
  max-width: calc(100% - var(--spacing));
}

/* line 233, scss/pages/homepage.scss */
.not-touch .knowledge__link {
  transition: transform 0.2s, opacity .2s,color .2s,border-color .2s,background-color .2s,box-shadow .2s;
  transform: translateX(100%);
}

/* line 237, scss/pages/homepage.scss */
.not-touch .knowledge__link.focus-visible {
  transition: opacity .2s,color .2s,border-color .2s,background-color .2s,box-shadow .2s;
}

/* line 244, scss/pages/homepage.scss */
.not-touch :hover > .knowledge__link, .not-touch .knowledge__link.focus-visible {
  transform: none;
}

/*# sourceMappingURL=homepage.css.map */